.msg_bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #ececec;
  border-bottom-left-radius: 0;
  min-width: 250px;
  width: fit-content;
  word-break: break-all;
}

.right_msg {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #579ffb;
  color: #fff;
  float: right;
  margin: 0 1rem 0 0;
  border-bottom-right-radius: 0;
  min-width: 250px;
  word-break: break-all;
}

.msg_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg_info_name {
  margin-right: 10px;
  font-weight: bold;
}
.msg_info_time {
  font-size: 0.85em;
}

.left_msg .msg_bubble {
  border-bottom-left-radius: 0;
}

.msg_text:has(a) {
  text-align: center;
}

.msg_text > a {
  color: #579ffb;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  margin: auto;
}

.right_msg .msg_text > a {
  color: #fff;
}

/* .right_msg {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  flex-direction: row-reverse;
  color: #fff;
  border-bottom-right-radius: 0;
  margin: 0 0 0 10px;
} */
