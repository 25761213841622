.chatbox {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.3rem;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 0.3rem;
}

.header_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header_buttons button {
  padding: 0.4rem 0.4rem;
}

.header_opts {
  display: flex;
}

@media (max-width: 1200px) {
  .chatbox {
    position: absolute;
    top: 0;
  }

  .wrapper {
    flex-direction: column;
  }

  .header {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    flex-direction: column-reverse;
  }

  .header div {
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-height: 40px;
  }

  .header_buttons {
    margin-bottom: -0.5rem;
  }
}
