.register {
  background-color: rgb(204, 218, 233);
  height: 100vh;
  width: 100%;
  padding: 5rem 0 0 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: auto;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.input_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  padding: 0 1rem;
  position: relative;
  gap: 0.5rem;
}

.error {
  width: 100%;
  margin-top: 0.5rem;
}
