.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  gap: 0.5rem;
  scrollbar-width: none;
  scrollbar-color: #8f8f8f #ffffff;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.container::-webkit-scrollbar-track {
  background: #ffffff;
}

.container::-webkit-scrollbar-thumb {
  background-color: #8f8f8f;
  border-radius: 12px;
  border: 1px solid #ffffff;
}
