.popup_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.199);
}

.popup {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 0.3rem;
    background-color: white;
    border-radius: 4px;
    padding: 1rem;
    width: fit-content;
    height:fit-content;
}