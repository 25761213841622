.wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    padding: 3rem;
    height: 100vh;
}

.rooms {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 0.3rem;
}

@media (max-width: 1200px) {
    .wrapper {
        flex-direction: column;
        gap: 1rem;
        padding: 0;
    }

    .wrapper {
        width: 100%;
        align-items: center;
        padding-top: 3rem;
    }

    .rooms {
        width: 100%;
        max-width: 700px;
    }
}