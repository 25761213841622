.container {
  display: flex;
  align-items: center;
}

.icon_settings {
  width: 40px;
  transition: transform 0.7s ease-in-out;
}
.icon_settings:hover,
.icon_settings:focus {
  transform: rotate(360deg);
}

.icon_del {
  width: 40px;
  transition: all 0.3s ease;
}

.icon_del:hover,
.icon_del:focus {
  width: 40px;
  animation: jello-horizontal 1s ease;
  animation-iteration-count: 1;
}

.icon_loc {
  width: 40px;
  transition: all 0.3s ease;
}

.icon_loc:hover,
.icon_loc:focus {
  width: 40px;
  animation: swing 1s ease;
  animation-iteration-count: 1;
}

.error {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.3rem;
}

.rooms {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.3rem;
}

.roomsmenu_popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  background-color: white;
  top: 0;
  left: -100%;
  padding-top: 3rem;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: #b7cfcb;
}

.rooms_menu_slidein {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  background-color: white;
  top: 0;
  left: -100%;
  padding-top: 3rem;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: #b7cfcb;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.rooms_menu_slideout {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  background-color: white;
  top: 0;
  left: -100%;
  padding-top: 3rem;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-out-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.button {
  all: unset;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .icon {
    width: 25px;
  }

  .rooms {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  .rooms_menu {
    display: none;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes slide-out-left {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}
